/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract } from "ethers";
export class IColony__factory {
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
const _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "bytes32",
                name: "txHash",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "string",
                name: "metadata",
                type: "string",
            },
        ],
        name: "Annotation",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address[]",
                name: "users",
                type: "address[]",
            },
            {
                indexed: false,
                internalType: "int256[]",
                name: "amounts",
                type: "int256[]",
            },
        ],
        name: "ColonyBootstrapped",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "fee",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "payoutRemainder",
                type: "uint256",
            },
        ],
        name: "ColonyFundsClaimed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "fromPot",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "toPot",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "token",
                type: "address",
            },
        ],
        name: "ColonyFundsMovedBetweenFundingPots",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "colonyNetwork",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "token",
                type: "address",
            },
        ],
        name: "ColonyInitialised",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "string",
                name: "metadata",
                type: "string",
            },
        ],
        name: "ColonyMetadata",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "rewardInverse",
                type: "uint256",
            },
        ],
        name: "ColonyRewardInverseSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "domainId",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint8",
                name: "role",
                type: "uint8",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "setTo",
                type: "bool",
            },
        ],
        name: "ColonyRoleSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "oldVersion",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newVersion",
                type: "uint256",
            },
        ],
        name: "ColonyUpgraded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "domainId",
                type: "uint256",
            },
        ],
        name: "DomainAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "domainId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "string",
                name: "metadata",
                type: "string",
            },
        ],
        name: "DomainMetadata",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "expenditureId",
                type: "uint256",
            },
        ],
        name: "ExpenditureAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "expenditureId",
                type: "uint256",
            },
        ],
        name: "ExpenditureCancelled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "expenditureId",
                type: "uint256",
            },
        ],
        name: "ExpenditureFinalized",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "expenditureId",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "slot",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "ExpenditurePayoutSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "expenditureId",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "slot",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "recipient",
                type: "address",
            },
        ],
        name: "ExpenditureRecipientSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "expenditureId",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "slot",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "skillId",
                type: "uint256",
            },
        ],
        name: "ExpenditureSkillSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "expenditureId",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
        ],
        name: "ExpenditureTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "fundingPotId",
                type: "uint256",
            },
        ],
        name: "FundingPotAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "paymentId",
                type: "uint256",
            },
        ],
        name: "PaymentAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "paymentId",
                type: "uint256",
            },
        ],
        name: "PaymentFinalized",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "paymentId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "PaymentPayoutSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "paymentId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "recipient",
                type: "address",
            },
        ],
        name: "PaymentRecipientSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "paymentId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "skillId",
                type: "uint256",
            },
        ],
        name: "PaymentSkillSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "fundingPotId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "PayoutClaimed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "RecoveryModeEntered",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "RecoveryModeExitApproved",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "RecoveryModeExited",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "setTo",
                type: "bool",
            },
        ],
        name: "RecoveryRoleSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "slot",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "fromValue",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "toValue",
                type: "bytes32",
            },
        ],
        name: "RecoveryStorageSlotSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "rewardPayoutId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "fee",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "rewardRemainder",
                type: "uint256",
            },
        ],
        name: "RewardPayoutClaimed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "rewardPayoutId",
                type: "uint256",
            },
        ],
        name: "RewardPayoutCycleEnded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "rewardPayoutId",
                type: "uint256",
            },
        ],
        name: "RewardPayoutCycleStarted",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "taskId",
                type: "uint256",
            },
        ],
        name: "TaskAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "taskId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "specificationHash",
                type: "bytes32",
            },
        ],
        name: "TaskBriefSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "taskId",
                type: "uint256",
            },
        ],
        name: "TaskCanceled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address[]",
                name: "reviewerAddresses",
                type: "address[]",
            },
        ],
        name: "TaskChangedViaSignatures",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "taskId",
                type: "uint256",
            },
        ],
        name: "TaskCompleted",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "taskId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "deliverableHash",
                type: "bytes32",
            },
        ],
        name: "TaskDeliverableSubmitted",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "taskId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "dueDate",
                type: "uint256",
            },
        ],
        name: "TaskDueDateSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "taskId",
                type: "uint256",
            },
        ],
        name: "TaskFinalized",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "taskId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "enum ColonyDataTypes.TaskRole",
                name: "role",
                type: "uint8",
            },
            {
                indexed: false,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "TaskPayoutSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "taskId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "enum ColonyDataTypes.TaskRole",
                name: "role",
                type: "uint8",
            },
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "TaskRoleUserSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "taskId",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "skillId",
                type: "uint256",
            },
        ],
        name: "TaskSkillSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "taskId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "enum ColonyDataTypes.TaskRole",
                name: "role",
                type: "uint8",
            },
            {
                indexed: false,
                internalType: "uint8",
                name: "rating",
                type: "uint8",
            },
        ],
        name: "TaskWorkRatingRevealed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [],
        name: "TokenUnlocked",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "TokensBurned",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "agent",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "who",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "TokensMinted",
        type: "event",
    },
    {
        inputs: [],
        name: "approveExitRecovery",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_slot",
                type: "uint256",
            },
        ],
        name: "checkNotAdditionalProtectedVariable",
        outputs: [],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "enterRecoveryMode",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "exitRecoveryMode",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "isInRecoveryMode",
        outputs: [
            {
                internalType: "bool",
                name: "inRecoveryMode",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "numRecoveryRoles",
        outputs: [
            {
                internalType: "uint64",
                name: "numRoles",
                type: "uint64",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
        ],
        name: "removeRecoveryRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
        ],
        name: "setRecoveryRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_slot",
                type: "uint256",
            },
            {
                internalType: "bytes32",
                name: "_value",
                type: "bytes32",
            },
        ],
        name: "setStorageSlotRecovery",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "authority",
        outputs: [
            {
                internalType: "address",
                name: "colonyAuthority",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "colonyOwner",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "version",
        outputs: [
            {
                internalType: "uint256",
                name: "colonyVersion",
                type: "uint256",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_newVersion",
                type: "uint256",
            },
        ],
        name: "upgrade",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "finishUpgrade",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getColonyNetwork",
        outputs: [
            {
                internalType: "address",
                name: "colonyNetwork",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getToken",
        outputs: [
            {
                internalType: "address",
                name: "tokenAddress",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_to",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "_action",
                type: "bytes",
            },
        ],
        name: "makeArbitraryTransaction",
        outputs: [
            {
                internalType: "bool",
                name: "success",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "_txHash",
                type: "bytes32",
            },
            {
                internalType: "string",
                name: "_metadata",
                type: "string",
            },
        ],
        name: "annotateTransaction",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "bool",
                name: "_setTo",
                type: "bool",
            },
        ],
        name: "setRootRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "bool",
                name: "_setTo",
                type: "bool",
            },
        ],
        name: "setArbitrationRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "bool",
                name: "_setTo",
                type: "bool",
            },
        ],
        name: "setArchitectureRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "bool",
                name: "_setTo",
                type: "bool",
            },
        ],
        name: "setFundingRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "bool",
                name: "_setTo",
                type: "bool",
            },
        ],
        name: "setAdministrationRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "bytes32",
                name: "_roles",
                type: "bytes32",
            },
        ],
        name: "setUserRoles",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "enum ColonyDataTypes.ColonyRole",
                name: "_role",
                type: "uint8",
            },
        ],
        name: "hasUserRole",
        outputs: [
            {
                internalType: "bool",
                name: "hasRole",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "enum ColonyDataTypes.ColonyRole",
                name: "_role",
                type: "uint8",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childDomainId",
                type: "uint256",
            },
        ],
        name: "hasInheritedUserRole",
        outputs: [
            {
                internalType: "bool",
                name: "hasRole",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childDomainId",
                type: "uint256",
            },
        ],
        name: "userCanSetRoles",
        outputs: [
            {
                internalType: "bool",
                name: "canSet",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domain",
                type: "uint256",
            },
        ],
        name: "getUserRoles",
        outputs: [
            {
                internalType: "bytes32",
                name: "roles",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes4",
                name: "_sig",
                type: "bytes4",
            },
        ],
        name: "getCapabilityRoles",
        outputs: [
            {
                internalType: "bytes32",
                name: "roles",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "int256",
                name: "_amount",
                type: "int256",
            },
        ],
        name: "emitDomainReputationReward",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "int256",
                name: "_amount",
                type: "int256",
            },
        ],
        name: "emitSkillReputationReward",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "int256",
                name: "_amount",
                type: "int256",
            },
        ],
        name: "emitDomainReputationPenalty",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "int256",
                name: "_amount",
                type: "int256",
            },
        ],
        name: "emitSkillReputationPenalty",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_colonyNetworkAddress",
                type: "address",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "initialiseColony",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "_metadata",
                type: "string",
            },
        ],
        name: "editColony",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "_users",
                type: "address[]",
            },
            {
                internalType: "int256[]",
                name: "_amount",
                type: "int256[]",
            },
        ],
        name: "bootstrapColony",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_wad",
                type: "uint256",
            },
        ],
        name: "mintTokens",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_guy",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_wad",
                type: "uint256",
            },
        ],
        name: "mintTokensFor",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "lockToken",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "lockId",
                type: "uint256",
            },
        ],
        name: "unlockTokenForUser",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "colonyName",
                type: "string",
            },
            {
                internalType: "string",
                name: "orbitdb",
                type: "string",
            },
        ],
        name: "registerColonyLabel",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "orbitdb",
                type: "string",
            },
        ],
        name: "updateColonyOrbitDB",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "version",
                type: "uint256",
            },
        ],
        name: "installExtension",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "newVersion",
                type: "uint256",
            },
        ],
        name: "upgradeExtension",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                internalType: "bool",
                name: "deprecated",
                type: "bool",
            },
        ],
        name: "deprecateExtension",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
        ],
        name: "uninstallExtension",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_parentDomainId",
                type: "uint256",
            },
        ],
        name: "addDomain",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_parentDomainId",
                type: "uint256",
            },
            {
                internalType: "string",
                name: "_metadata",
                type: "string",
            },
        ],
        name: "addDomain",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "string",
                name: "_metadata",
                type: "string",
            },
        ],
        name: "editDomain",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "getDomain",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "skillId",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "fundingPotId",
                        type: "uint256",
                    },
                ],
                internalType: "struct ColonyDataTypes.Domain",
                name: "domain",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getDomainCount",
        outputs: [
            {
                internalType: "uint256",
                name: "count",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes",
                name: "key",
                type: "bytes",
            },
            {
                internalType: "bytes",
                name: "value",
                type: "bytes",
            },
            {
                internalType: "uint256",
                name: "branchMask",
                type: "uint256",
            },
            {
                internalType: "bytes32[]",
                name: "siblings",
                type: "bytes32[]",
            },
        ],
        name: "verifyReputationProof",
        outputs: [
            {
                internalType: "bool",
                name: "isValid",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
        ],
        name: "makeExpenditure",
        outputs: [
            {
                internalType: "uint256",
                name: "expenditureId",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_newOwner",
                type: "address",
            },
        ],
        name: "transferExpenditure",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_newOwner",
                type: "address",
            },
        ],
        name: "transferExpenditureViaArbitration",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "cancelExpenditure",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "finalizeExpenditure",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_slot",
                type: "uint256",
            },
            {
                internalType: "address payable",
                name: "_recipient",
                type: "address",
            },
        ],
        name: "setExpenditureRecipient",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_slot",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "setExpenditurePayout",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_slot",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
        ],
        name: "setExpenditureSkill",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_slot",
                type: "uint256",
            },
            {
                internalType: "int256",
                name: "_payoutModifier",
                type: "int256",
            },
        ],
        name: "setExpenditurePayoutModifier",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_slot",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_claimDelay",
                type: "uint256",
            },
        ],
        name: "setExpenditureClaimDelay",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_storageSlot",
                type: "uint256",
            },
            {
                internalType: "bool[]",
                name: "_mask",
                type: "bool[]",
            },
            {
                internalType: "bytes32[]",
                name: "_keys",
                type: "bytes32[]",
            },
            {
                internalType: "bytes32",
                name: "_value",
                type: "bytes32",
            },
        ],
        name: "setExpenditureState",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_slot",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "claimExpenditurePayout",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getExpenditureCount",
        outputs: [
            {
                internalType: "uint256",
                name: "count",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "getExpenditure",
        outputs: [
            {
                components: [
                    {
                        internalType: "enum ColonyDataTypes.ExpenditureStatus",
                        name: "status",
                        type: "uint8",
                    },
                    {
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "fundingPotId",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "domainId",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "finalizedTimestamp",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "globalClaimDelay",
                        type: "uint256",
                    },
                ],
                internalType: "struct ColonyDataTypes.Expenditure",
                name: "expenditure",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_slot",
                type: "uint256",
            },
        ],
        name: "getExpenditureSlot",
        outputs: [
            {
                components: [
                    {
                        internalType: "address payable",
                        name: "recipient",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "claimDelay",
                        type: "uint256",
                    },
                    {
                        internalType: "int256",
                        name: "payoutModifier",
                        type: "int256",
                    },
                    {
                        internalType: "uint256[]",
                        name: "skills",
                        type: "uint256[]",
                    },
                ],
                internalType: "struct ColonyDataTypes.ExpenditureSlot",
                name: "expenditureSlot",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_slot",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "getExpenditureSlotPayout",
        outputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "address payable",
                name: "_recipient",
                type: "address",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
        ],
        name: "addPayment",
        outputs: [
            {
                internalType: "uint256",
                name: "paymentId",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "finalizePayment",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address payable",
                name: "_recipient",
                type: "address",
            },
        ],
        name: "setPaymentRecipient",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
        ],
        name: "setPaymentSkill",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "setPaymentPayout",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "getPayment",
        outputs: [
            {
                components: [
                    {
                        internalType: "address payable",
                        name: "recipient",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "finalized",
                        type: "bool",
                    },
                    {
                        internalType: "uint256",
                        name: "fundingPotId",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "domainId",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256[]",
                        name: "skills",
                        type: "uint256[]",
                    },
                ],
                internalType: "struct ColonyDataTypes.Payment",
                name: "payment",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "claimPayment",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getPaymentCount",
        outputs: [
            {
                internalType: "uint256",
                name: "count",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "bytes32",
                name: "_specificationHash",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_dueDate",
                type: "uint256",
            },
        ],
        name: "makeTask",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getTaskCount",
        outputs: [
            {
                internalType: "uint256",
                name: "count",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "getTaskChangeNonce",
        outputs: [
            {
                internalType: "uint256",
                name: "nonce",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint8[]",
                name: "_sigV",
                type: "uint8[]",
            },
            {
                internalType: "bytes32[]",
                name: "_sigR",
                type: "bytes32[]",
            },
            {
                internalType: "bytes32[]",
                name: "_sigS",
                type: "bytes32[]",
            },
            {
                internalType: "uint8[]",
                name: "_mode",
                type: "uint8[]",
            },
            {
                internalType: "uint256",
                name: "_value",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
        ],
        name: "executeTaskChange",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint8[]",
                name: "_sigV",
                type: "uint8[]",
            },
            {
                internalType: "bytes32[]",
                name: "_sigR",
                type: "bytes32[]",
            },
            {
                internalType: "bytes32[]",
                name: "_sigS",
                type: "bytes32[]",
            },
            {
                internalType: "uint8[]",
                name: "_mode",
                type: "uint8[]",
            },
            {
                internalType: "uint256",
                name: "_value",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
        ],
        name: "executeTaskRoleAssignment",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint8",
                name: "_role",
                type: "uint8",
            },
            {
                internalType: "bytes32",
                name: "_ratingSecret",
                type: "bytes32",
            },
        ],
        name: "submitTaskWorkRating",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint8",
                name: "_role",
                type: "uint8",
            },
            {
                internalType: "uint8",
                name: "_rating",
                type: "uint8",
            },
            {
                internalType: "bytes32",
                name: "_salt",
                type: "bytes32",
            },
        ],
        name: "revealTaskWorkRating",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "_salt",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "_value",
                type: "uint256",
            },
        ],
        name: "generateSecret",
        outputs: [
            {
                internalType: "bytes32",
                name: "secret",
                type: "bytes32",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "getTaskWorkRatingSecretsInfo",
        outputs: [
            {
                internalType: "uint256",
                name: "nSecrets",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "lastSubmittedAt",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint8",
                name: "_role",
                type: "uint8",
            },
        ],
        name: "getTaskWorkRatingSecret",
        outputs: [
            {
                internalType: "bytes32",
                name: "secret",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address payable",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
        ],
        name: "setTaskManagerRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address payable",
                name: "_user",
                type: "address",
            },
        ],
        name: "setTaskEvaluatorRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address payable",
                name: "_user",
                type: "address",
            },
        ],
        name: "setTaskWorkerRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "removeTaskEvaluatorRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "removeTaskWorkerRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
        ],
        name: "setTaskSkill",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "bytes32",
                name: "_specificationHash",
                type: "bytes32",
            },
        ],
        name: "setTaskBrief",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_dueDate",
                type: "uint256",
            },
        ],
        name: "setTaskDueDate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "bytes32",
                name: "_deliverableHash",
                type: "bytes32",
            },
        ],
        name: "submitTaskDeliverable",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "bytes32",
                name: "_deliverableHash",
                type: "bytes32",
            },
            {
                internalType: "bytes32",
                name: "_ratingSecret",
                type: "bytes32",
            },
        ],
        name: "submitTaskDeliverableAndRating",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "finalizeTask",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "cancelTask",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "completeTask",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "getTask",
        outputs: [
            {
                internalType: "bytes32",
                name: "specificationHash",
                type: "bytes32",
            },
            {
                internalType: "bytes32",
                name: "deliverableHash",
                type: "bytes32",
            },
            {
                internalType: "enum ColonyDataTypes.TaskStatus",
                name: "status",
                type: "uint8",
            },
            {
                internalType: "uint256",
                name: "dueDate",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "fundingPotId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "completionTimestamp",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "domainId",
                type: "uint256",
            },
            {
                internalType: "uint256[]",
                name: "skillIds",
                type: "uint256[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint8",
                name: "_role",
                type: "uint8",
            },
        ],
        name: "getTaskRole",
        outputs: [
            {
                components: [
                    {
                        internalType: "address payable",
                        name: "user",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "rateFail",
                        type: "bool",
                    },
                    {
                        internalType: "enum ColonyDataTypes.TaskRatings",
                        name: "rating",
                        type: "uint8",
                    },
                ],
                internalType: "struct ColonyDataTypes.Role",
                name: "role",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_rewardInverse",
                type: "uint256",
            },
        ],
        name: "setRewardInverse",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getRewardInverse",
        outputs: [
            {
                internalType: "uint256",
                name: "rewardInverse",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint8",
                name: "_role",
                type: "uint8",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "getTaskPayout",
        outputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "setTaskManagerPayout",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "setTaskEvaluatorPayout",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "setTaskWorkerPayout",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_managerAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_evaluatorAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_workerAmount",
                type: "uint256",
            },
        ],
        name: "setAllTaskPayouts",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "uint8",
                name: "_role",
                type: "uint8",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "claimTaskPayout",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "key",
                type: "bytes",
            },
            {
                internalType: "bytes",
                name: "value",
                type: "bytes",
            },
            {
                internalType: "uint256",
                name: "branchMask",
                type: "uint256",
            },
            {
                internalType: "bytes32[]",
                name: "siblings",
                type: "bytes32[]",
            },
        ],
        name: "startNextRewardPayout",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_payoutId",
                type: "uint256",
            },
            {
                internalType: "uint256[7]",
                name: "_squareRoots",
                type: "uint256[7]",
            },
            {
                internalType: "bytes",
                name: "key",
                type: "bytes",
            },
            {
                internalType: "bytes",
                name: "value",
                type: "bytes",
            },
            {
                internalType: "uint256",
                name: "branchMask",
                type: "uint256",
            },
            {
                internalType: "bytes32[]",
                name: "siblings",
                type: "bytes32[]",
            },
        ],
        name: "claimRewardPayout",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_payoutId",
                type: "uint256",
            },
        ],
        name: "getRewardPayoutInfo",
        outputs: [
            {
                components: [
                    {
                        internalType: "bytes32",
                        name: "reputationState",
                        type: "bytes32",
                    },
                    {
                        internalType: "uint256",
                        name: "colonyWideReputation",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "totalTokens",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "tokenAddress",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "blockTimestamp",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "amountRemaining",
                        type: "uint256",
                    },
                    {
                        internalType: "bool",
                        name: "finalized",
                        type: "bool",
                    },
                ],
                internalType: "struct ColonyDataTypes.RewardPayoutCycle",
                name: "rewardPayoutCycle",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_payoutId",
                type: "uint256",
            },
        ],
        name: "finalizeRewardPayout",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "getFundingPot",
        outputs: [
            {
                internalType: "enum ColonyDataTypes.FundingPotAssociatedType",
                name: "associatedType",
                type: "uint8",
            },
            {
                internalType: "uint256",
                name: "associatedTypeId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "payoutsWeCannotMake",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getFundingPotCount",
        outputs: [
            {
                internalType: "uint256",
                name: "count",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_potId",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "getFundingPotBalance",
        outputs: [
            {
                internalType: "uint256",
                name: "balance",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_potId",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "getFundingPotPayout",
        outputs: [
            {
                internalType: "uint256",
                name: "payout",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_fromChildSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_toChildSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_fromPot",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_toPot",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "moveFundsBetweenPots",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_fromChildSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_toChildSkillIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_fromPot",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_toPot",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "moveFundsBetweenPots",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "claimColonyFunds",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "getNonRewardPotsTotal",
        outputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_approvee",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "approveStake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "obligateStake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "deobligateStake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_permissionDomainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_obligator",
                type: "address",
            },
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_recipient",
                type: "address",
            },
        ],
        name: "transferStake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "address",
                name: "_obligator",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
        ],
        name: "getApproval",
        outputs: [
            {
                internalType: "uint256",
                name: "approval",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "address",
                name: "_obligator",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_domainId",
                type: "uint256",
            },
        ],
        name: "getObligation",
        outputs: [
            {
                internalType: "uint256",
                name: "obligation",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_fundingPotId",
                type: "uint256",
            },
        ],
        name: "getDomainFromFundingPot",
        outputs: [
            {
                internalType: "uint256",
                name: "domainId",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "burnTokens",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "unlockToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                internalType: "address",
                name: "spender",
                type: "address",
            },
        ],
        name: "updateApprovalAmount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                internalType: "address",
                name: "spender",
                type: "address",
            },
        ],
        name: "getTokenApproval",
        outputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "token",
                type: "address",
            },
        ],
        name: "getTotalTokenApproval",
        outputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
