var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MaxUint256 } from 'ethers/constants';
import { getMoveFundsPermissionProofs, } from './commonExtensions';
import { addExtensions as addExtensionsV6, } from './extensionsV6';
/*
 * Extension Methods
 */
function moveFundsBetweenPotsWithProofs(_fromPot, _toPot, _amount, _token, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, fromChildSkillIndex, toChildSkillIndex,] = yield getMoveFundsPermissionProofs(this, _fromPot, _toPot);
        return this.moveFundsBetweenPots(permissionDomainId, MaxUint256, permissionDomainId, fromChildSkillIndex, toChildSkillIndex, _fromPot, _toPot, _amount, _token, overrides);
    });
}
/*
 * Estimates
 */
function estimateMoveFundsBetweenPotsWithProofs(_fromPot, _toPot, _amount, _token) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, fromChildSkillIndex, toChildSkillIndex,] = yield getMoveFundsPermissionProofs(this, _fromPot, _toPot);
        return this.estimate.moveFundsBetweenPots(permissionDomainId, MaxUint256, permissionDomainId, fromChildSkillIndex, toChildSkillIndex, _fromPot, _toPot, _amount, _token);
    });
}
/*
 * Bindings
 */
export const addExtensions = (instance, networkClient) => {
    // Add all extensions from v6, because these are also still valid
    const extendedInstance = addExtensionsV6(instance, networkClient);
    /*
     * We basically disable the signature type of the initial (pre V7) method
     *
     * This is because we overload the method, but not in a way that TS likes, as we
     * add the overloaded argument in the middle, and not at the end.
     */
    // eslint-disable-next-line max-len
    extendedInstance.moveFundsBetweenPotsWithProofs = moveFundsBetweenPotsWithProofs.bind(extendedInstance);
    /*
     * We basically disable the signature type of the initial (pre V7) method
     *
     * This is because we overload the method, but not in a way that TS likes, as we
     * add the overloaded argument in the middle, and not at the end.
     */
    // eslint-disable-next-line prettier/prettier
    extendedInstance.estimate
        // eslint-disable-next-line max-len
        .moveFundsBetweenPotsWithProofs = estimateMoveFundsBetweenPotsWithProofs.bind(extendedInstance);
    return extendedInstance;
};
