import { Contract } from 'ethers';
import { IColonyFactory as IColonyFactoryV3 } from '../../contracts/3/IColonyFactory';
import { IColonyFactory as IColonyFactoryV4 } from '../../contracts/4/IColonyFactory';
import { addExtensions, } from './extensions/extensionsV4';
import { getAllAbiEvents, getAbiFunctions } from '../../utils';
import { ColonyVersion } from '../../versions';
export default function getColonyClient(address, signerOrProvider) {
    const abiFunctions = getAbiFunctions(IColonyFactoryV4, address, signerOrProvider);
    /*
     * Get all events, including the ones from v3 and v4, as well as the current ones
     */
    const abiEvents = getAllAbiEvents([IColonyFactoryV4, IColonyFactoryV3], address, signerOrProvider);
    /*
     * For this to work we have to create our own instance of the contract, so
     * that we can pass in the merged abi events
     */
    const colonyClientV4 = new Contract(address, [...abiFunctions, ...abiEvents], signerOrProvider);
    colonyClientV4.clientVersion = ColonyVersion.BurgundyGlider;
    addExtensions(colonyClientV4, this);
    return colonyClientV4;
}
