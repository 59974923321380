var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ContractFactory } from 'ethers';
import { ClientType, Network, colonyNetworkAddresses, REPUTATION_ORACLE_ENDPOINT, } from '../constants';
import { ColonyVersion } from '../versions';
// @TODO this _HAS_ to be the newest version _ALWAYS_. Let's try to figure out a way to make sure of this
import { IColonyNetwork__factory as IColonyNetworkFactory } from '../contracts/6/factories/IColonyNetwork__factory';
import { abi as tokenAbi, bytecode as tokenBytecode, } from '../contracts/deploy/MetaTxToken.json';
import getColonyVersionClient from './Colony/ColonyVersionClient';
import getColonyClientV1 from './Colony/ColonyClientV1';
import getColonyClientV2 from './Colony/ColonyClientV2';
import getColonyClientV3 from './Colony/ColonyClientV3';
import getColonyClientV4 from './Colony/ColonyClientV4';
import getColonyClientV5 from './Colony/ColonyClientV5';
import getColonyClientV6 from './Colony/ColonyClientV6';
import getColonyClientV7 from './Colony/ColonyClientV7';
import getTokenClient from './TokenClient';
import getTokenLockingClient from './TokenLockingClient';
/**
 * The main entry point for accessing the deployed colonyNetwork contracts
 *
 * Specify a network and an ethers compatible singer or provider to get back an
 * initialized and extended (ethers) contract client for the colonyNetwork. From
 * here you can access different colonies, extensions, ENS and other features of Colony.
 *
 * Example
 * ```ts
 * import { getColonyNetworkClient, Network } = from '@colony/colony-js';
 * import { JsonRpcProvider } from 'ethers/providers';
 *
 * // For local connections (run an Ethereum node on port 8545);
 * const provider = new JsonRpcProvider();
 *
 * // Just for reading data - to sign transactions we need to pass in a signer.
 * const networkClient = await getColonyNetworkClient(Network.Mainnet, provider);
 * ```
 *
 * @param network One of the available options. See [[Network]].
 * @param signerOrProvider An [ethers](https://github.com/ethers-io/ethers.js/)
 * compatible signer or provider instance
 * @param options Here you can supply options for accessing certain contracts
 * (mostly used in local/dev environments)
 */
const getColonyNetworkClient = (network = Network.Mainnet, signerOrProvider, options) => {
    const networkAddress = options && options.networkAddress
        ? options.networkAddress
        : colonyNetworkAddresses[network];
    if (!networkAddress) {
        throw new Error(`Could not get ColonyNetwork address for ${network}. Please specify`);
    }
    const networkClient = IColonyNetworkFactory.connect(networkAddress, signerOrProvider);
    networkClient.clientType = ClientType.NetworkClient;
    networkClient.network = network;
    networkClient.reputationOracleEndpoint =
        (options && options.reputationOracleEndpoint) || REPUTATION_ORACLE_ENDPOINT;
    networkClient.getTokenLockingClient = () => __awaiter(void 0, void 0, void 0, function* () {
        const tokenLockingAddress = yield networkClient.getTokenLocking();
        return getTokenLockingClient(tokenLockingAddress, signerOrProvider);
    });
    networkClient.getColonyClient = (addressOrId) => __awaiter(void 0, void 0, void 0, function* () {
        let colonyAddress;
        if (typeof addressOrId == 'number') {
            colonyAddress = yield networkClient.getColony(addressOrId);
        }
        else {
            colonyAddress = addressOrId;
        }
        const colonyVersionClient = getColonyVersionClient(colonyAddress, signerOrProvider);
        // This is *kinda* hacky, but I have no better idea ¯\_(ツ)_/¯
        // We have to get the version somehow before instantiating the right contract version
        const versionBN = yield colonyVersionClient.version();
        const version = versionBN.toNumber();
        let colonyClient;
        switch (version) {
            case ColonyVersion.GoerliGlider: {
                colonyClient = getColonyClientV1.call(networkClient, colonyAddress, signerOrProvider);
                break;
            }
            case ColonyVersion.Glider: {
                colonyClient = getColonyClientV2.call(networkClient, colonyAddress, signerOrProvider);
                break;
            }
            case ColonyVersion.AuburnGlider: {
                colonyClient = getColonyClientV3.call(networkClient, colonyAddress, signerOrProvider);
                break;
            }
            case ColonyVersion.BurgundyGlider: {
                colonyClient = getColonyClientV4.call(networkClient, colonyAddress, signerOrProvider);
                break;
            }
            case ColonyVersion.LightweightSpaceship: {
                colonyClient = getColonyClientV5.call(networkClient, colonyAddress, signerOrProvider);
                break;
            }
            case ColonyVersion.CeruleanLightweightSpaceship: {
                colonyClient = getColonyClientV6.call(networkClient, colonyAddress, signerOrProvider);
                break;
            }
            case ColonyVersion.DandelionLightweightSpaceship: {
                colonyClient = getColonyClientV7.call(networkClient, colonyAddress, signerOrProvider);
                break;
            }
            default: {
                throw new Error('Colony version not supported');
            }
        }
        const tokenAddress = yield colonyClient.getToken();
        colonyClient.tokenClient = yield getTokenClient(tokenAddress, signerOrProvider);
        // @TODO where to put the extensions?
        return colonyClient;
    });
    networkClient.getMetaColonyClient = () => __awaiter(void 0, void 0, void 0, function* () {
        const metaColonyAddress = yield networkClient.getMetaColony();
        return networkClient.getColonyClient(metaColonyAddress);
    });
    networkClient.lookupRegisteredENSDomainWithNetworkPatches = (addr) => __awaiter(void 0, void 0, void 0, function* () {
        const domain = yield networkClient.lookupRegisteredENSDomain(addr);
        if (domain && networkClient.network === Network.Goerli) {
            const [name, scope] = domain.split('.');
            return `${name}.${scope}.joincolony.test`;
        }
        if (domain &&
            (networkClient.network === Network.Xdai ||
                networkClient.network === Network.XdaiFork)) {
            const [name, scope] = domain.split('.');
            return `${name}.${scope}.joincolony.colonyxdai`;
        }
        return domain;
    });
    networkClient.deployToken = (name, symbol, decimals = 18, overrides) => __awaiter(void 0, void 0, void 0, function* () {
        const tokenFactory = new ContractFactory(tokenAbi, tokenBytecode, networkClient.signer);
        const tokenContract = yield tokenFactory.deploy(name, symbol, decimals, overrides);
        yield tokenContract.deployed();
        return tokenContract.deployTransaction;
    });
    networkClient.estimate.deployToken = (name, symbol, decimals = 18) => __awaiter(void 0, void 0, void 0, function* () {
        const tokenFactory = new ContractFactory(tokenAbi, tokenBytecode);
        const deployTx = tokenFactory.getDeployTransaction(name, symbol, decimals);
        return networkClient.provider.estimateGas(deployTx);
    });
    return networkClient;
};
export default getColonyNetworkClient;
