var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getAddress, isHexString, parseBytes32String } from 'ethers/utils';
import { ClientType, TokenClientType, tokenAddresses } from '../constants';
import { Token__factory as TokenFactory } from '../contracts/6/factories/Token__factory';
import { TokenERC20__factory as TokenErc20Factory } from '../contracts/6/factories/TokenERC20__factory';
import { TokenSAI__factory as TokenSaiFactory } from '../contracts/6/factories/TokenSAI__factory';
const isSai = (address) => getAddress(address) === tokenAddresses.SAI;
const getTokenClient = (address, signerOrProvider) => __awaiter(void 0, void 0, void 0, function* () {
    let tokenClient;
    let isColonyToken = false;
    tokenClient = TokenFactory.connect(address, signerOrProvider);
    // Colony tokens have the `locked()` method. We assume that when it exists on
    // the contract we have a ColonyToken 🦆. This might not be true though, so can't rely
    // on this 100% when trying to call contract methods
    try {
        yield tokenClient.locked();
        isColonyToken = true;
    }
    catch (_a) {
        isColonyToken = false;
    }
    if (isColonyToken) {
        tokenClient.tokenClientType = TokenClientType.Colony;
    }
    else if (isSai(address)) {
        tokenClient = TokenSaiFactory.connect(address, signerOrProvider);
        tokenClient.tokenClientType = TokenClientType.Sai;
    }
    else {
        tokenClient = TokenErc20Factory.connect(address, signerOrProvider);
        tokenClient.tokenClientType = TokenClientType.Erc20;
    }
    // Before we go, let's check if this resembles a valid ERC20 token, for good measure
    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore https://github.com/ethereum-ts/TypeChain/pull/255
        yield tokenClient.estimate.transfer(address, 0, {
            from: address,
        });
    }
    catch (err) {
        throw new Error(`Token is probably not a valid ERC20 token, got ${err}`);
    }
    tokenClient.clientType = ClientType.TokenClient;
    tokenClient.getTokenInfo = () => __awaiter(void 0, void 0, void 0, function* () {
        let name = yield tokenClient.name();
        // Special case for contracts with bytes32 strings (I'm looking at you, DAI)
        if (isHexString(name)) {
            name = parseBytes32String(name);
        }
        let symbol = yield tokenClient.symbol();
        if (isHexString(symbol)) {
            symbol = parseBytes32String(symbol);
        }
        const decimals = yield tokenClient.decimals();
        return {
            name,
            symbol,
            decimals,
        };
    });
    return tokenClient;
});
export default getTokenClient;
