import { getExtensionCompatibilityMap } from './utils';
var Extension;
(function (Extension) {
    Extension["OneTxPayment"] = "OneTxPayment";
    Extension["CoinMachine"] = "CoinMachine";
    Extension["VotingReputation"] = "VotingReputation";
})(Extension || (Extension = {}));
/*
 * Colony / Network Versioning
 */
/**
 * Available versions for colonies that could be deployed. When a new version for a colony needs to be supported, it should be added here. The versions are incremented integers.
 */
export var ColonyVersion;
(function (ColonyVersion) {
    /** Only deployed to Goerli, hence not present on mainnet deployments */
    ColonyVersion[ColonyVersion["GoerliGlider"] = 1] = "GoerliGlider";
    ColonyVersion[ColonyVersion["Glider"] = 2] = "Glider";
    ColonyVersion[ColonyVersion["AuburnGlider"] = 3] = "AuburnGlider";
    ColonyVersion[ColonyVersion["BurgundyGlider"] = 4] = "BurgundyGlider";
    ColonyVersion[ColonyVersion["LightweightSpaceship"] = 5] = "LightweightSpaceship";
    ColonyVersion[ColonyVersion["CeruleanLightweightSpaceship"] = 6] = "CeruleanLightweightSpaceship";
    ColonyVersion[ColonyVersion["DandelionLightweightSpaceship"] = 7] = "DandelionLightweightSpaceship";
})(ColonyVersion || (ColonyVersion = {}));
// These are the corresponding git release tags for the deployed versions of the Colony Network
const colonyReleaseMap = {
    [ColonyVersion.GoerliGlider]: 'glider-rc.1',
    [ColonyVersion.Glider]: 'glider',
    [ColonyVersion.AuburnGlider]: 'auburn-glider',
    [ColonyVersion.BurgundyGlider]: 'burgundy-glider',
    [ColonyVersion.LightweightSpaceship]: 'lwss',
    [ColonyVersion.CeruleanLightweightSpaceship]: `clwss`,
    [ColonyVersion.DandelionLightweightSpaceship]: `dlwss`,
};
/**
 * The newest colony version. This will be used when deploying new colonies */
export const CurrentColonyVersion = parseInt(ColonyVersion[Object.keys(ColonyVersion).slice(-1)[0]], 10);
/*
 * kept for legacy purposes
 */
export const CurrentVersion = CurrentColonyVersion;
/*
 * One Transaction Payment Extension Versioning
 */
export var OneTxPaymentExtensionVersion;
(function (OneTxPaymentExtensionVersion) {
    OneTxPaymentExtensionVersion[OneTxPaymentExtensionVersion["CeruleanLightweightSpaceship"] = 1] = "CeruleanLightweightSpaceship";
    OneTxPaymentExtensionVersion[OneTxPaymentExtensionVersion["DandelionLightweightSpaceship"] = 2] = "DandelionLightweightSpaceship";
})(OneTxPaymentExtensionVersion || (OneTxPaymentExtensionVersion = {}));
const oneTxPaymentReleaseMap = {
    [OneTxPaymentExtensionVersion.CeruleanLightweightSpaceship]: `clwss`,
    [OneTxPaymentExtensionVersion.DandelionLightweightSpaceship]: `dlwss`,
};
export const CurrentOneTxPaymentVersion = parseInt(OneTxPaymentExtensionVersion[Object.keys(OneTxPaymentExtensionVersion).slice(-1)[0]], 10);
const OneTxPaymentExtensionVersionIncompatibilityMap = {
    [OneTxPaymentExtensionVersion.CeruleanLightweightSpaceship]: [],
    [OneTxPaymentExtensionVersion.DandelionLightweightSpaceship]: [],
};
const OneTxPaymentExtensionVersionCompatibilityMap = getExtensionCompatibilityMap(OneTxPaymentExtensionVersionIncompatibilityMap, ColonyVersion);
/*
 * Coin Machine Extension Versioning
 */
export var CoinMachineExtensionVersion;
(function (CoinMachineExtensionVersion) {
    CoinMachineExtensionVersion[CoinMachineExtensionVersion["CeruleanLightweightSpaceship"] = 1] = "CeruleanLightweightSpaceship";
    CoinMachineExtensionVersion[CoinMachineExtensionVersion["DandelionLightweightSpaceship"] = 2] = "DandelionLightweightSpaceship";
})(CoinMachineExtensionVersion || (CoinMachineExtensionVersion = {}));
const coinMachineReleaseMap = {
    [CoinMachineExtensionVersion.CeruleanLightweightSpaceship]: `clwss`,
    [CoinMachineExtensionVersion.DandelionLightweightSpaceship]: `dlwss`,
};
export const CurrentCoinMachineVersion = parseInt(CoinMachineExtensionVersion[Object.keys(CoinMachineExtensionVersion).slice(-1)[0]], 10);
const CoinMachineExtensionVersionIncompatibilityMap = {
    [CoinMachineExtensionVersion.CeruleanLightweightSpaceship]: [],
    [CoinMachineExtensionVersion.DandelionLightweightSpaceship]: [],
};
const CoinMachineExtensionVersionCompatibilityMap = getExtensionCompatibilityMap(CoinMachineExtensionVersionIncompatibilityMap, ColonyVersion);
/*
 * Voting Reputation Extension Versioning
 */
export var VotingReputationExtensionVersion;
(function (VotingReputationExtensionVersion) {
    VotingReputationExtensionVersion[VotingReputationExtensionVersion["CeruleanLightweightSpaceship"] = 1] = "CeruleanLightweightSpaceship";
    VotingReputationExtensionVersion[VotingReputationExtensionVersion["DandelionLightweightSpaceship"] = 2] = "DandelionLightweightSpaceship";
})(VotingReputationExtensionVersion || (VotingReputationExtensionVersion = {}));
const votingReputationReleaseMap = {
    [VotingReputationExtensionVersion.CeruleanLightweightSpaceship]: `clwss`,
    [VotingReputationExtensionVersion.DandelionLightweightSpaceship]: `dlwss`,
};
export const CurrentVotingReputationVersion = parseInt(VotingReputationExtensionVersion[Object.keys(VotingReputationExtensionVersion).slice(-1)[0]], 10);
const VotingReputationExtensionVersionIncompatibilityMap = {
    [VotingReputationExtensionVersion.CeruleanLightweightSpaceship]: [],
    [VotingReputationExtensionVersion.DandelionLightweightSpaceship]: [
        ColonyVersion.GoerliGlider,
        ColonyVersion.Glider,
        ColonyVersion.AuburnGlider,
        ColonyVersion.BurgundyGlider,
        ColonyVersion.LightweightSpaceship,
        ColonyVersion.CeruleanLightweightSpaceship,
    ],
};
const VotingReputationExtensionVersionCompatibilityMap = getExtensionCompatibilityMap(VotingReputationExtensionVersionIncompatibilityMap, ColonyVersion);
/*
 * Extensions Compatibility and Incompatibility Map
 * (Aggregates from the various extensions ones)
 */
export const extensionsIncompatibilityMap = {
    [Extension.OneTxPayment]: OneTxPaymentExtensionVersionIncompatibilityMap,
    [Extension.CoinMachine]: CoinMachineExtensionVersionIncompatibilityMap,
    // eslint-disable-next-line max-len
    [Extension.VotingReputation]: VotingReputationExtensionVersionIncompatibilityMap,
};
export const extensionsCompatibilityMap = {
    [Extension.OneTxPayment]: OneTxPaymentExtensionVersionCompatibilityMap,
    [Extension.CoinMachine]: CoinMachineExtensionVersionCompatibilityMap,
    // eslint-disable-next-line max-len
    [Extension.VotingReputation]: VotingReputationExtensionVersionCompatibilityMap,
};
/*
 * Release Map
 */
export const releaseMap = Object.assign(Object.assign({}, colonyReleaseMap), { colony: Object.assign({}, colonyReleaseMap), extension: {
        oneTxPayment: Object.assign({}, oneTxPaymentReleaseMap),
        coinMachine: Object.assign({}, coinMachineReleaseMap),
        votingReputation: Object.assign({}, votingReputationReleaseMap),
    } });
