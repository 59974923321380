var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ColonyRole } from '../../../constants';
import { addExtensions as addCommonExtensions, getPermissionProofs, } from './commonExtensions';
function setArbitrationRoleWithProofs(_user, _domainId, _setTo, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        let proofs;
        // This method has two potential permissions, so we try both of them
        try {
            proofs = yield getPermissionProofs(this, _domainId, ColonyRole.ArchitectureSubdomain_DEPRECATED);
        }
        catch (err) {
            proofs = yield getPermissionProofs(this, _domainId, ColonyRole.Root);
        }
        const [permissionDomainId, childSkillIndex] = proofs;
        return this.setArbitrationRole(permissionDomainId, childSkillIndex, _user, _domainId, _setTo, overrides);
    });
}
function estimateSetArbitrationRoleWithProofs(_user, _domainId, _setTo) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Architecture);
        return this.estimate.setArbitrationRole(permissionDomainId, childSkillIndex, _user, _domainId, _setTo);
    });
}
export const addExtensions = (instance, networkClient) => {
    const extendedInstance = addCommonExtensions(instance, networkClient);
    /* eslint-disable no-param-reassign, max-len */
    extendedInstance.setArbitrationRoleWithProofs = setArbitrationRoleWithProofs.bind(extendedInstance);
    extendedInstance.estimate.setArbitrationRoleWithProofs = estimateSetArbitrationRoleWithProofs.bind(extendedInstance);
    /* eslint-enable no-param-reassign, max-len */
    return extendedInstance;
};
