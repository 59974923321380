var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ColonyRole, ROOT_DOMAIN_ID } from '../../../constants';
import { getPermissionProofs } from './commonExtensions';
import { addExtensions as addExtensionsV4, } from './extensionsV4';
/*
 * Extension Methods
 */
function emitDomainReputationPenaltyWithProofs(_domainId, _user, _amount, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Arbitration);
        return this.emitDomainReputationPenalty(permissionDomainId, childSkillIndex, _domainId, _user, _amount, overrides);
    });
}
function setUserRolesWithProofs(_user, _domainId, _roles, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const isRootDomain = _domainId === ROOT_DOMAIN_ID.toString();
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, isRootDomain ? ColonyRole.Root : ColonyRole.Architecture);
        return this.setUserRoles(permissionDomainId, childSkillIndex, _user, _domainId, _roles, overrides);
    });
}
function transferStakeWithProofs(_obligator, _user, _domainId, _amount, _recipient, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Arbitration);
        return this.transferStake(permissionDomainId, childSkillIndex, _obligator, _user, _domainId, _amount, _recipient, overrides);
    });
}
function addDomainWithProofs(_parentDomainId, _metadata, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        let overrideOverload = overrides;
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _parentDomainId, ColonyRole.Architecture);
        /*
         * Otherwise, because of the positioning of `overrides`, it might get confused
         * with it
         */
        if (typeof _metadata === 'string') {
            return this['addDomain(uint256,uint256,uint256,string)'](permissionDomainId, childSkillIndex, _parentDomainId, _metadata, overrideOverload);
        }
        /*
         * Since we're not calling the overloaded version, which has the metadata string,
         * the `_metadata` argument now stands place for the `overrides` and contains
         * it's values
         */
        overrideOverload = _metadata;
        return this['addDomain(uint256,uint256,uint256)'](permissionDomainId, childSkillIndex, _parentDomainId, overrideOverload);
    });
}
function editDomainWithProofs(_domainId, _metadata, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Architecture);
        return this.editDomain(permissionDomainId, childSkillIndex, _domainId, _metadata, overrides);
    });
}
function setExpenditureStateWithProofs(_id, _storageSlot, _mask, _keys, _value, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getExpenditure(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Arbitration);
        return this.setExpenditureState(permissionDomainId, childSkillIndex, _id, _storageSlot, _mask, _keys, _value, overrides);
    });
}
/*
 * Estimates
 */
function estimateEmitDomainReputationPenaltyWithProofs(_domainId, _user, _amount) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Arbitration);
        return this.estimate.emitDomainReputationPenalty(permissionDomainId, childSkillIndex, _domainId, _user, _amount);
    });
}
function estimateSetUserRolesWithProofs(_user, _domainId, _roles) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Arbitration);
        return this.estimate.setUserRoles(permissionDomainId, childSkillIndex, _user, _domainId, _roles);
    });
}
function estimateTransferStakeWithProofs(_obligator, _user, _domainId, _amount, _recipient) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Arbitration);
        return this.estimate.transferStake(permissionDomainId, childSkillIndex, _obligator, _user, _domainId, _amount, _recipient);
    });
}
function estimateAddDomainWithProofs(_parentDomainId, _metadata) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _parentDomainId, ColonyRole.Architecture);
        if (_metadata) {
            return this.estimate['addDomain(uint256,uint256,uint256,string)'](permissionDomainId, childSkillIndex, _parentDomainId, _metadata);
        }
        return this.estimate['addDomain(uint256,uint256,uint256)'](permissionDomainId, childSkillIndex, _parentDomainId);
    });
}
function estimateEditDomainWithProofs(_domainId, _metadata) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Architecture);
        return this.estimate.editDomain(permissionDomainId, childSkillIndex, _domainId, _metadata);
    });
}
function estimateSetExpenditureStateWithProofs(_id, _storageSlot, _mask, _keys, _value) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getExpenditure(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Arbitration);
        return this.estimate.setExpenditureState(permissionDomainId, childSkillIndex, _id, _storageSlot, _mask, _keys, _value);
    });
}
/*
 * Bindings
 */
export const addExtensions = (instance, networkClient) => {
    // Add all extensions from v4, because these are also still valid
    const extendedInstance = addExtensionsV4(instance, networkClient);
    /* eslint-disable max-len */
    extendedInstance.emitDomainReputationPenaltyWithProofs = emitDomainReputationPenaltyWithProofs.bind(extendedInstance);
    extendedInstance.setUserRolesWithProofs = setUserRolesWithProofs.bind(extendedInstance);
    extendedInstance.transferStakeWithProofs = transferStakeWithProofs.bind(extendedInstance);
    /*
     * We basically disable the signature type of the initial (pre V3) method
     *
     * This is because we overload the method, but not in a way that TS likes, as we
     * add the overloaded argument in the middle, and not at the end.
     */
    extendedInstance.addDomainWithProofs = addDomainWithProofs.bind(extendedInstance);
    extendedInstance.editDomainWithProofs = editDomainWithProofs.bind(extendedInstance);
    extendedInstance.setExpenditureStateWithProofs = setExpenditureStateWithProofs.bind(extendedInstance);
    extendedInstance.estimate.emitDomainReputationPenaltyWithProofs = estimateEmitDomainReputationPenaltyWithProofs.bind(extendedInstance);
    extendedInstance.estimate.setUserRolesWithProofs = estimateSetUserRolesWithProofs.bind(extendedInstance);
    extendedInstance.estimate.transferStakeWithProofs = estimateTransferStakeWithProofs.bind(extendedInstance);
    /*
     * We basically disable the signature type of the initial (pre V3) method
     *
     * This is because we overload the method, but not in a way that TS likes, as we
     * add the overloaded argument in the middle, and not at the end.
     */
    extendedInstance.estimate
        .addDomainWithProofs = estimateAddDomainWithProofs.bind(extendedInstance);
    extendedInstance.estimate.editDomainWithProofs = estimateEditDomainWithProofs.bind(extendedInstance);
    extendedInstance.estimate.setExpenditureStateWithProofs = estimateSetExpenditureStateWithProofs.bind(extendedInstance);
    /* eslint-enable max-len */
    return extendedInstance;
};
