import { getAddress } from 'ethers/utils';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isEqual = require('lodash.isequal');
// @TODO ethers v5 has an isAddress function
export const isAddress = (address) => {
    try {
        getAddress(address);
    }
    catch (_a) {
        return false;
    }
    return true;
};
export const getAbiEvents = (factory, address, signerOrProvider) => {
    const { interface: { abi }, } = factory.connect(address, signerOrProvider);
    return abi.filter(({ type }) => type === 'event');
};
export const getAbiFunctions = (factory, address, signerOrProvider) => {
    const { interface: { abi }, } = factory.connect(address, signerOrProvider);
    return abi.filter(({ type }) => type !== 'event');
};
export const getUniqueAbiEvents = (baseEventsAbi, eventsAbi) => eventsAbi.filter((event) => !baseEventsAbi.find((baseEvent) => isEqual(baseEvent, event)));
export const getAllAbiEvents = (factories, address, signerOrProvider) => {
    let abiEvents = [];
    factories.map((factory) => {
        const currentFactoryAbiEvents = getAbiEvents(factory, address, signerOrProvider);
        const currentUniqueAbiEvents = getUniqueAbiEvents(abiEvents, currentFactoryAbiEvents);
        abiEvents = [...abiEvents, ...currentUniqueAbiEvents];
        return null;
    });
    return abiEvents;
};
export const getExtensionCompatibilityMap = (incompatibilityMap, colonyVersions) => {
    const compatibilityMap = {};
    const allColonyVersions = Object.keys(colonyVersions)
        .map((version) => parseInt(version, 10))
        .filter((version) => !!version);
    const extensionVersions = Object.keys(incompatibilityMap);
    extensionVersions.map((version) => {
        const currentCompatibleVersions = allColonyVersions.filter((colonyVersion) => !incompatibilityMap[version].includes(colonyVersion));
        compatibilityMap[parseInt(version, 10)] = currentCompatibleVersions;
        return null;
    });
    return compatibilityMap;
};
