// A minimal version of the ColonyClient contract that only supports the `version` method
import { Contract } from 'ethers';
const abi = [
    {
        constant: true,
        inputs: [],
        name: 'version',
        outputs: [
            {
                name: 'colonyVersion',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'pure',
        type: 'function',
    },
];
export default function getColonyVersionClient(address, signerOrProvider) {
    return new Contract(address, abi, signerOrProvider);
}
