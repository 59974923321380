import { Contract } from 'ethers';
import { IColonyFactory as IColonyFactoryV3 } from '../../contracts/3/IColonyFactory';
import { IColonyFactory as IColonyFactoryV4 } from '../../contracts/4/IColonyFactory';
import { IColony__factory as IColonyFactoryV5 } from '../../contracts/5/factories/IColony__factory';
import { IColony__factory as IColonyFactoryV6 } from '../../contracts/colony/6/factories/IColony__factory';
import { addExtensions, } from './extensions/extensionsV6';
import { getAllAbiEvents, getAbiFunctions } from '../../utils';
import { ColonyVersion } from '../../versions';
export default function getColonyClient(address, signerOrProvider) {
    const abiFunctions = getAbiFunctions(IColonyFactoryV6, address, signerOrProvider);
    /*
     * Get all events, including the ones from v3, as well as the current ones
     */
    const abiEvents = getAllAbiEvents([IColonyFactoryV6, IColonyFactoryV5, IColonyFactoryV4, IColonyFactoryV3], address, signerOrProvider);
    /*
     * For this to work we have to create our own instance of the contract, so
     * that we can pass in the merged abi events
     */
    const colonyClientV6 = new Contract(address, [...abiFunctions, ...abiEvents], signerOrProvider);
    colonyClientV6.clientVersion = ColonyVersion.CeruleanLightweightSpaceship;
    addExtensions(colonyClientV6, this);
    return colonyClientV6;
}
