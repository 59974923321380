import { Contract } from 'ethers';
import { IColonyFactory as IColonyFactoryV3 } from '../../contracts/3/IColonyFactory';
import { IColonyFactory as IColonyFactoryV4 } from '../../contracts/4/IColonyFactory';
import { IColony__factory as IColonyFactoryV5 } from '../../contracts/5/factories/IColony__factory';
import { addExtensions, } from './extensions/extensionsV5';
import { getAllAbiEvents, getAbiFunctions } from '../../utils';
import { ColonyVersion } from '../../versions';
export default function getColonyClient(address, signerOrProvider) {
    const abiFunctions = getAbiFunctions(IColonyFactoryV5, address, signerOrProvider);
    /*
     * Get all events, including the ones from v3 and v4, as well as the current ones
     */
    const abiEvents = getAllAbiEvents([IColonyFactoryV5, IColonyFactoryV4, IColonyFactoryV3], address, signerOrProvider);
    /*
     * For this to work we have to create our own instance of the contract, so
     * that we can pass in the merged abi events
     */
    const colonyClientV5 = new Contract(address, [...abiFunctions, ...abiEvents], signerOrProvider);
    colonyClientV5.clientVersion = ColonyVersion.LightweightSpaceship;
    addExtensions(colonyClientV5, this);
    return colonyClientV5;
}
