/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract } from "ethers";
export class IColonyNetwork__factory {
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
const _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "auction",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
            },
        ],
        name: "AuctionCreated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "colonyId",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "colonyAddress",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "token",
                type: "address",
            },
        ],
        name: "ColonyAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "colony",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "label",
                type: "bytes32",
            },
        ],
        name: "ColonyLabelRegistered",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "resolver",
                type: "address",
            },
        ],
        name: "ColonyNetworkInitialised",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "version",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "resolver",
                type: "address",
            },
        ],
        name: "ColonyVersionAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "version",
                type: "uint256",
            },
        ],
        name: "ExtensionAddedToNetwork",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "colony",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "deprecated",
                type: "bool",
            },
        ],
        name: "ExtensionDeprecated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "colony",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "version",
                type: "uint256",
            },
        ],
        name: "ExtensionInstalled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "colony",
                type: "address",
            },
        ],
        name: "ExtensionUninstalled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "colony",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "version",
                type: "uint256",
            },
        ],
        name: "ExtensionUpgraded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "metaColony",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "rootSkillId",
                type: "uint256",
            },
        ],
        name: "MetaColonyCreated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "miningCycleResolver",
                type: "address",
            },
        ],
        name: "MiningCycleResolverSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "feeInverse",
                type: "uint256",
            },
        ],
        name: "NetworkFeeInverseSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "RecoveryModeEntered",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "RecoveryModeExitApproved",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "RecoveryModeExited",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "setTo",
                type: "bool",
            },
        ],
        name: "RecoveryRoleSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "slot",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "fromValue",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "toValue",
                type: "bytes32",
            },
        ],
        name: "RecoveryStorageSlotSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "ens",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "rootNode",
                type: "bytes32",
            },
        ],
        name: "RegistrarInitialised",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "miner",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "tokensLost",
                type: "uint256",
            },
        ],
        name: "ReputationMinerPenalised",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "hash",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "nLeaves",
                type: "uint256",
            },
        ],
        name: "ReputationMiningCycleComplete",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "inactiveReputationMiningCycle",
                type: "address",
            },
        ],
        name: "ReputationMiningInitialised",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "ReputationMiningRewardSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "newHash",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newNLeaves",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address[]",
                name: "stakers",
                type: "address[]",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "reward",
                type: "uint256",
            },
        ],
        name: "ReputationRootHashSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "skillId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "parentSkillId",
                type: "uint256",
            },
        ],
        name: "SkillAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "tokenLocking",
                type: "address",
            },
        ],
        name: "TokenLockingAddressSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "status",
                type: "bool",
            },
        ],
        name: "TokenWhitelisted",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "label",
                type: "bytes32",
            },
        ],
        name: "UserLabelRegistered",
        type: "event",
    },
    {
        inputs: [],
        name: "approveExitRecovery",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_slot",
                type: "uint256",
            },
        ],
        name: "checkNotAdditionalProtectedVariable",
        outputs: [],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "enterRecoveryMode",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "exitRecoveryMode",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "isInRecoveryMode",
        outputs: [
            {
                internalType: "bool",
                name: "inRecoveryMode",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "numRecoveryRoles",
        outputs: [
            {
                internalType: "uint64",
                name: "numRoles",
                type: "uint64",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
        ],
        name: "removeRecoveryRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
        ],
        name: "setRecoveryRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_slot",
                type: "uint256",
            },
            {
                internalType: "bytes32",
                name: "_value",
                type: "bytes32",
            },
        ],
        name: "setStorageSlotRecovery",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes4",
                name: "interfaceID",
                type: "bytes4",
            },
        ],
        name: "supportsInterface",
        outputs: [
            {
                internalType: "bool",
                name: "status",
                type: "bool",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_reputationMiningCycle",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "int256",
                name: "_amount",
                type: "int256",
            },
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_colony",
                type: "address",
            },
            {
                internalType: "uint128",
                name: "_nUpdates",
                type: "uint128",
            },
            {
                internalType: "uint128",
                name: "_nPreviousUpdates",
                type: "uint128",
            },
        ],
        name: "setReplacementReputationUpdateLogEntry",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_reputationMiningCycle",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "getReplacementReputationUpdateLogEntry",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "user",
                        type: "address",
                    },
                    {
                        internalType: "int256",
                        name: "amount",
                        type: "int256",
                    },
                    {
                        internalType: "uint256",
                        name: "skillId",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "colony",
                        type: "address",
                    },
                    {
                        internalType: "uint128",
                        name: "nUpdates",
                        type: "uint128",
                    },
                    {
                        internalType: "uint128",
                        name: "nPreviousUpdates",
                        type: "uint128",
                    },
                ],
                internalType: "struct ColonyNetworkDataTypes.ReputationLogEntry",
                name: "reputationLogEntry",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_reputationMiningCycle",
                type: "address",
            },
        ],
        name: "getReplacementReputationUpdateLogsExist",
        outputs: [
            {
                internalType: "bool",
                name: "exists",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getMetaColony",
        outputs: [
            {
                internalType: "address payable",
                name: "colonyAddress",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getColonyCount",
        outputs: [
            {
                internalType: "uint256",
                name: "count",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_colony",
                type: "address",
            },
        ],
        name: "isColony",
        outputs: [
            {
                internalType: "bool",
                name: "addressIsColony",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_parentSkillId",
                type: "uint256",
            },
        ],
        name: "addSkill",
        outputs: [
            {
                internalType: "uint256",
                name: "skillId",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
        ],
        name: "getSkill",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint128",
                        name: "nParents",
                        type: "uint128",
                    },
                    {
                        internalType: "uint128",
                        name: "nChildren",
                        type: "uint128",
                    },
                    {
                        internalType: "uint256[]",
                        name: "parents",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "children",
                        type: "uint256[]",
                    },
                    {
                        internalType: "bool",
                        name: "globalSkill",
                        type: "bool",
                    },
                    {
                        internalType: "bool",
                        name: "deprecated",
                        type: "bool",
                    },
                ],
                internalType: "struct ColonyNetworkDataTypes.Skill",
                name: "skill",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
        ],
        name: "deprecateSkill",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
            {
                internalType: "int256",
                name: "_amount",
                type: "int256",
            },
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
        ],
        name: "appendReputationUpdateLog",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getSkillCount",
        outputs: [
            {
                internalType: "uint256",
                name: "count",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getReputationMiningSkillId",
        outputs: [
            {
                internalType: "uint256",
                name: "skillId",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_tokenLockingAddress",
                type: "address",
            },
        ],
        name: "setTokenLocking",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getTokenLocking",
        outputs: [
            {
                internalType: "address",
                name: "lockingAddress",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_tokenAddress",
                type: "address",
            },
        ],
        name: "createMetaColony",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_tokenAddress",
                type: "address",
            },
        ],
        name: "createColony",
        outputs: [
            {
                internalType: "address",
                name: "colonyAddress",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_tokenAddress",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_version",
                type: "uint256",
            },
            {
                internalType: "string",
                name: "_colonyName",
                type: "string",
            },
            {
                internalType: "string",
                name: "_metadata",
                type: "string",
            },
        ],
        name: "createColony",
        outputs: [
            {
                internalType: "address",
                name: "colonyAddress",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_tokenAddress",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_version",
                type: "uint256",
            },
            {
                internalType: "string",
                name: "_colonyName",
                type: "string",
            },
        ],
        name: "createColony",
        outputs: [
            {
                internalType: "address",
                name: "colonyAddress",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_tokenAddress",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_version",
                type: "uint256",
            },
            {
                internalType: "string",
                name: "_colonyName",
                type: "string",
            },
            {
                internalType: "string",
                name: "_orbitdb",
                type: "string",
            },
            {
                internalType: "bool",
                name: "_useExtensionManager",
                type: "bool",
            },
        ],
        name: "createColony",
        outputs: [
            {
                internalType: "address",
                name: "colonyAddress",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_version",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_resolver",
                type: "address",
            },
        ],
        name: "addColonyVersion",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_resolver",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_version",
                type: "uint256",
            },
        ],
        name: "initialise",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_id",
                type: "uint256",
            },
        ],
        name: "getColony",
        outputs: [
            {
                internalType: "address",
                name: "colonyAddress",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getCurrentColonyVersion",
        outputs: [
            {
                internalType: "uint256",
                name: "version",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_parentSkillIndex",
                type: "uint256",
            },
        ],
        name: "getParentSkillId",
        outputs: [
            {
                internalType: "uint256",
                name: "skillId",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_skillId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_childSkillIndex",
                type: "uint256",
            },
        ],
        name: "getChildSkillId",
        outputs: [
            {
                internalType: "uint256",
                name: "skillId",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bool",
                name: "_active",
                type: "bool",
            },
        ],
        name: "getReputationMiningCycle",
        outputs: [
            {
                internalType: "address",
                name: "repMiningCycleAddress",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_timeStaked",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_submissonIndex",
                type: "uint256",
            },
        ],
        name: "calculateMinerWeight",
        outputs: [
            {
                internalType: "uint256",
                name: "minerWeight",
                type: "uint256",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_version",
                type: "uint256",
            },
        ],
        name: "getColonyVersionResolver",
        outputs: [
            {
                internalType: "address",
                name: "resolverAddress",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "newHash",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "newNLeaves",
                type: "uint256",
            },
            {
                internalType: "address[]",
                name: "stakers",
                type: "address[]",
            },
        ],
        name: "setReputationRootHash",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "newHash",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "newNLeaves",
                type: "uint256",
            },
            {
                internalType: "address[]",
                name: "stakers",
                type: "address[]",
            },
            {
                internalType: "uint256",
                name: "reward",
                type: "uint256",
            },
        ],
        name: "setReputationRootHash",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "startNextCycle",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "initialiseReputationMining",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getReputationRootHash",
        outputs: [
            {
                internalType: "bytes32",
                name: "rootHash",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getReputationRootHashNLeaves",
        outputs: [
            {
                internalType: "uint256",
                name: "nLeaves",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getReputationRootHashNNodes",
        outputs: [
            {
                internalType: "uint256",
                name: "nNodes",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "startTokenAuction",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_ens",
                type: "address",
            },
            {
                internalType: "bytes32",
                name: "_rootNode",
                type: "bytes32",
            },
        ],
        name: "setupRegistrar",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "username",
                type: "string",
            },
            {
                internalType: "string",
                name: "orbitdb",
                type: "string",
            },
        ],
        name: "registerUserLabel",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "colonyName",
                type: "string",
            },
            {
                internalType: "string",
                name: "orbitdb",
                type: "string",
            },
        ],
        name: "registerColonyLabel",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "orbitdb",
                type: "string",
            },
        ],
        name: "updateColonyOrbitDB",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "orbitdb",
                type: "string",
            },
        ],
        name: "updateUserOrbitDB",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "node",
                type: "bytes32",
            },
        ],
        name: "getProfileDBAddress",
        outputs: [
            {
                internalType: "string",
                name: "orbitDB",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "addr",
                type: "address",
            },
        ],
        name: "lookupRegisteredENSDomain",
        outputs: [
            {
                internalType: "string",
                name: "domain",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "node",
                type: "bytes32",
            },
        ],
        name: "addr",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getENSRegistrar",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "miningResolverAddress",
                type: "address",
            },
        ],
        name: "setMiningResolver",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getMiningResolver",
        outputs: [
            {
                internalType: "address",
                name: "miningResolverAddress",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "resolver",
                type: "address",
            },
        ],
        name: "addExtensionToNetwork",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "version",
                type: "uint256",
            },
        ],
        name: "installExtension",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "newVersion",
                type: "uint256",
            },
        ],
        name: "upgradeExtension",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                internalType: "bool",
                name: "deprecated",
                type: "bool",
            },
        ],
        name: "deprecateExtension",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
        ],
        name: "uninstallExtension",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "version",
                type: "uint256",
            },
        ],
        name: "getExtensionResolver",
        outputs: [
            {
                internalType: "address",
                name: "resolver",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "extensionId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "colony",
                type: "address",
            },
        ],
        name: "getExtensionInstallation",
        outputs: [
            {
                internalType: "address",
                name: "installation",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getFeeInverse",
        outputs: [
            {
                internalType: "uint256",
                name: "_feeInverse",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_feeInverse",
                type: "uint256",
            },
        ],
        name: "setFeeInverse",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
        ],
        name: "getPayoutWhitelist",
        outputs: [
            {
                internalType: "bool",
                name: "status",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
            {
                internalType: "bool",
                name: "_status",
                type: "bool",
            },
        ],
        name: "setPayoutWhitelist",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "_stakers",
                type: "address[]",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "punishStakers",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "stakeForMining",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "unstakeForMining",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_user",
                type: "address",
            },
        ],
        name: "getMiningStake",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "timestamp",
                        type: "uint256",
                    },
                ],
                internalType: "struct ColonyNetworkDataTypes.MiningStake",
                name: "_info",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_recipient",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "reward",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "burnUnneededRewards",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_recipient",
                type: "address",
            },
        ],
        name: "claimMiningReward",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "setReputationMiningCycleReward",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getReputationMiningCycleReward",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
