import { IColonyFactory } from '../../contracts/1/IColonyFactory';
import { ColonyVersion } from '../../versions';
import { addExtensions } from './extensions/commonExtensions';
import { setPaymentDomainWithProofs, estimateSetPaymentDomainWithProofs, } from './extensions/SetPaymentDomain';
export default function getColonyClient(address, signerOrProvider) {
    const colonyClient = IColonyFactory.connect(address, signerOrProvider);
    colonyClient.clientVersion = ColonyVersion.GoerliGlider;
    addExtensions(colonyClient, this);
    /* eslint-disable max-len */
    colonyClient.setPaymentDomainWithProofs = setPaymentDomainWithProofs.bind(colonyClient);
    colonyClient.estimate.setPaymentDomainWithProofs = estimateSetPaymentDomainWithProofs.bind(colonyClient);
    /* eslint-enable max-len */
    return colonyClient;
}
