var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ColonyRole } from '../../../constants';
import { getPermissionProofs } from './commonExtensions';
export function setPaymentDomainWithProofs(_id, _domainId, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getPayment(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Administration);
        return this.setPaymentDomain(permissionDomainId, childSkillIndex, _id, _domainId, overrides);
    });
}
export function estimateSetPaymentDomainWithProofs(_id, _domainId) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getPayment(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Administration);
        return this.estimate.setPaymentDomain(permissionDomainId, childSkillIndex, _id, _domainId);
    });
}
