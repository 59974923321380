@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");

.loading {
  max-width: 700px;
  margin: 100px auto;
  padding: 100px;
}

.eventList {
  max-width: 700px;
  margin: 50px auto;
  border-radius: 6px;
  background: white;
  overflow: hidden;
  box-shadow: 0px 0px 8px 1px rgba(62, 118, 244, 0.14);
}

.eventItem {
  margin: 1px;
  height: 90px;
  background: white;
  display: flex;
  border: 0px solid rgba(62, 118, 244, 0.14);
  box-shadow: 0px 1px 8px 1px rgba(62, 118, 244, 0.14);
  font-size: 14px;
}

.eventDate {
  font-size: 12px;
  color: #b3b3b3;
}

.eventItem:hover {
  background: #f5f5f5;
}

.eventDescription {
  display: grid;
}

.eventDescription p {
  padding-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 7px;
}

.eventDescription p:hover {
  text-overflow: initial;
  overflow: auto;
}

.avatar {
  height: 37px;
  width: 37px;
  margin-top: 27px;
  margin-bottom: 27px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
}
