// A minimal version of the Colony Extension contract that only supports the `version` method
import { Contract } from 'ethers';
const abi = [
    {
        inputs: [],
        name: 'version',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'pure',
        type: 'function',
    },
];
export default function getExtensionVersionClient(address, signerOrProvider) {
    return new Contract(address, abi, signerOrProvider);
}
