var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ColonyRole } from '../../../constants';
import { getPermissionProofs } from './commonExtensions';
import { addExtensions as addExtensionsV3, } from './extensionsV3';
function hasInheritedUserRoleWithProofs(_user, _domainId, _role) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, _role, _user);
        return this.hasInheritedUserRole(_user, permissionDomainId, _role, childSkillIndex, _domainId);
    });
}
function makeExpenditureWithProofs(_domainId, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Administration);
        return this.makeExpenditure(permissionDomainId, childSkillIndex, _domainId, overrides);
    });
}
function setExpenditureClaimDelayWithProofs(_id, _slot, _claimDelay, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getExpenditure(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Arbitration);
        return this.setExpenditureClaimDelay(permissionDomainId, childSkillIndex, _id, _slot, _claimDelay, overrides);
    });
}
function setExpenditurePayoutModifierWithProofs(_id, _slot, _payoutModifier, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getExpenditure(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Arbitration);
        return this.setExpenditurePayoutModifier(permissionDomainId, childSkillIndex, _id, _slot, _payoutModifier, overrides);
    });
}
function transferExpenditureViaArbitrationWithProofs(_id, _newOwner, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getExpenditure(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Arbitration);
        return this.transferExpenditureViaArbitration(permissionDomainId, childSkillIndex, _id, _newOwner, overrides);
    });
}
function estimateMakeExpenditureWithProofs(_domainId) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Administration);
        return this.estimate.makeExpenditure(permissionDomainId, childSkillIndex, _domainId);
    });
}
function estimateSetExpenditureClaimDelayWithProofs(_id, _slot, _claimDelay) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getExpenditure(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Arbitration);
        return this.estimate.setExpenditureClaimDelay(permissionDomainId, childSkillIndex, _id, _slot, _claimDelay);
    });
}
function estimateSetExpenditurePayoutModifierWithProofs(_id, _slot, _payoutModifier) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getExpenditure(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Arbitration);
        return this.estimate.setExpenditurePayoutModifier(permissionDomainId, childSkillIndex, _id, _slot, _payoutModifier);
    });
}
function estimateTransferExpenditureViaArbitrationWithProofs(_newOwner, _id) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getExpenditure(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Arbitration);
        return this.estimate.transferExpenditureViaArbitration(permissionDomainId, childSkillIndex, _id, _newOwner);
    });
}
export const addExtensions = (instance, networkClient) => {
    // Add all extensions from v3, because these are also still valid
    const extendedInstance = addExtensionsV3(instance, networkClient);
    /* eslint-disable no-param-reassign, max-len */
    extendedInstance.hasInheritedUserRoleWithProofs = hasInheritedUserRoleWithProofs.bind(extendedInstance);
    extendedInstance.makeExpenditureWithProofs = makeExpenditureWithProofs.bind(extendedInstance);
    extendedInstance.setExpenditureClaimDelayWithProofs = setExpenditureClaimDelayWithProofs.bind(extendedInstance);
    extendedInstance.setExpenditurePayoutModifierWithProofs = setExpenditurePayoutModifierWithProofs.bind(extendedInstance);
    extendedInstance.transferExpenditureViaArbitrationWithProofs = transferExpenditureViaArbitrationWithProofs.bind(extendedInstance);
    extendedInstance.estimate.makeExpenditureWithProofs = estimateMakeExpenditureWithProofs.bind(extendedInstance);
    extendedInstance.estimate.setExpenditureClaimDelayWithProofs = estimateSetExpenditureClaimDelayWithProofs.bind(extendedInstance);
    extendedInstance.estimate.setExpenditurePayoutModifierWithProofs = estimateSetExpenditurePayoutModifierWithProofs.bind(extendedInstance);
    extendedInstance.estimate.transferExpenditureViaArbitrationWithProofs = estimateTransferExpenditureViaArbitrationWithProofs.bind(extendedInstance);
    /* eslint-enable no-param-reassign, max-len */
    return extendedInstance;
};
