import getCoinMachineClient from './CoinMachineClient';
import getOneTxPaymentClient from './OneTxPaymentClient';
import getVotingReputationClient from './VotingReputationClient';
export var Extension;
(function (Extension) {
    Extension["CoinMachine"] = "CoinMachine";
    // FundingQueue = 'FundingQueue',
    Extension["OneTxPayment"] = "OneTxPayment";
    Extension["VotingReputation"] = "VotingReputation";
})(Extension || (Extension = {}));
// Provide all factory funcitons to valid colony extensions
export const extensionFactoryMap = {
    [Extension.CoinMachine]: getCoinMachineClient,
    [Extension.OneTxPayment]: getOneTxPaymentClient,
    [Extension.VotingReputation]: getVotingReputationClient,
};
export const extensions = Object.keys(extensionFactoryMap);
