var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ClientType, ColonyRole } from '../../constants';
import { OneTxPayment__factory as OneTxPaymentFactory } from '../../contracts/6/factories/OneTxPayment__factory';
import { getPermissionProofs, } from '../../clients/Colony/extensions/commonExtensions';
const getExtensionPermissionProofs = (colonyClient, domainId, address) => __awaiter(void 0, void 0, void 0, function* () {
    const [fundingPDID, fundingCSI] = yield getPermissionProofs(colonyClient, domainId, ColonyRole.Funding, address);
    const [adminPDID, adminCSI] = yield getPermissionProofs(colonyClient, domainId, ColonyRole.Administration, address);
    if (!fundingPDID.eq(adminPDID) || !fundingCSI.eq(adminCSI)) {
        // @TODO: this can surely be improved
        throw new Error(
        // eslint-disable-next-line max-len
        `${address || 'User'} has to have the funding and administration role in the same domain`);
    }
    return [adminPDID, adminCSI];
});
const getOneTxPaymentClient = (address, colonyClient) => {
    const oneTxPaymentClient = OneTxPaymentFactory.connect(address, colonyClient.signer || colonyClient.provider);
    oneTxPaymentClient.clientType = ClientType.OneTxPaymentClient;
    oneTxPaymentClient.makePaymentWithProofs = (_workers, _tokens, _amounts, _domainId, _skillId, overrides) => __awaiter(void 0, void 0, void 0, function* () {
        const [extensionPDID, extensionCSI] = yield getExtensionPermissionProofs(colonyClient, _domainId, oneTxPaymentClient.address);
        const [userPDID, userCSI] = yield getExtensionPermissionProofs(colonyClient, _domainId);
        return oneTxPaymentClient.makePayment(extensionPDID, extensionCSI, userPDID, userCSI, _workers, _tokens, _amounts, _domainId, _skillId, overrides);
    });
    oneTxPaymentClient.makePaymentFundedFromDomainWithProofs = (_workers, _tokens, _amounts, _domainId, _skillId, overrides) => __awaiter(void 0, void 0, void 0, function* () {
        const [extensionPDID, extensionCSI] = yield getExtensionPermissionProofs(colonyClient, _domainId, oneTxPaymentClient.address);
        const [userPDID, userCSI] = yield getExtensionPermissionProofs(colonyClient, _domainId);
        return oneTxPaymentClient.makePaymentFundedFromDomain(extensionPDID, extensionCSI, userPDID, userCSI, _workers, _tokens, _amounts, _domainId, _skillId, overrides);
    });
    oneTxPaymentClient.estimate.makePaymentWithProofs = (_workers, _tokens, _amounts, _domainId, _skillId) => __awaiter(void 0, void 0, void 0, function* () {
        const [extensionPDID, extensionCSI] = yield getExtensionPermissionProofs(colonyClient, _domainId, oneTxPaymentClient.address);
        const [userPDID, userCSI] = yield getExtensionPermissionProofs(colonyClient, _domainId);
        return oneTxPaymentClient.estimate.makePayment(extensionPDID, extensionCSI, userPDID, userCSI, _workers, _tokens, _amounts, _domainId, _skillId);
    });
    oneTxPaymentClient.estimate.makePaymentFundedFromDomainWithProofs = (_workers, _tokens, _amounts, _domainId, _skillId) => __awaiter(void 0, void 0, void 0, function* () {
        const [extensionPDID, extensionCSI] = yield getExtensionPermissionProofs(colonyClient, _domainId, oneTxPaymentClient.address);
        const [userPDID, userCSI] = yield getExtensionPermissionProofs(colonyClient, _domainId);
        return oneTxPaymentClient.estimate.makePaymentFundedFromDomain(extensionPDID, extensionCSI, userPDID, userCSI, _workers, _tokens, _amounts, _domainId, _skillId);
    });
    return oneTxPaymentClient;
};
export default getOneTxPaymentClient;
