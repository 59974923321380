var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ContractFactory } from 'ethers';
import { bigNumberify } from 'ethers/utils';
import { MaxUint256, AddressZero } from 'ethers/constants';
import { isAddress } from '../../../utils';
import { ClientType, ColonyRole, FundingPotAssociatedType, ROOT_DOMAIN_ID, } from '../../../constants';
import { ColonyVersion } from '../../../versions';
import { IColonyFactory } from '../../../contracts/4/IColonyFactory';
import getExtensionVersionClient from '../../Extensions/ExtensionVersionClient';
import { abi as tokenAuthorityAbi, bytecode as tokenAuthorityBytecode, } from '../../../contracts/deploy/TokenAuthority.json';
import { getExtensionHash } from '../../../helpers';
export const getPotDomain = (contract, potId) => __awaiter(void 0, void 0, void 0, function* () {
    const { associatedType, associatedTypeId } = yield contract.getFundingPot(potId);
    // In case we add types to this later, we use the official colonyNetwork
    // function available in v5+
    if (contract.clientVersion === ColonyVersion.LightweightSpaceship) {
        return contract.getDomainFromFundingPot(potId);
    }
    switch (associatedType) {
        case FundingPotAssociatedType.Unassigned: {
            // This is probably the reward pot
            return ROOT_DOMAIN_ID;
        }
        case FundingPotAssociatedType.Domain: {
            return associatedTypeId;
        }
        case FundingPotAssociatedType.Payment: {
            const { domainId } = yield contract.getPayment(associatedTypeId);
            return domainId;
        }
        case FundingPotAssociatedType.Task: {
            const { domainId } = yield contract.getTask(associatedTypeId);
            return domainId;
        }
        default: {
            throw new Error(`No valid domain found for pot ${potId}`);
        }
    }
});
export const getChildIndex = (contract, parentDomainId, domainId) => __awaiter(void 0, void 0, void 0, function* () {
    if (bigNumberify(parentDomainId).eq(bigNumberify(domainId))) {
        return MaxUint256;
    }
    const { skillId: parentSkillId } = yield contract.getDomain(parentDomainId);
    const { skillId } = yield contract.getDomain(domainId);
    const { children } = yield contract.networkClient.getSkill(parentSkillId);
    const idx = children.findIndex((childSkillId) => childSkillId.eq(skillId));
    return bigNumberify(idx);
});
export const getPermissionProofs = (contract, domainId, role, customAddress) => __awaiter(void 0, void 0, void 0, function* () {
    const walletAddress = customAddress || (yield contract.signer.getAddress());
    const hasPermissionInDomain = yield contract.hasUserRole(walletAddress, domainId, role);
    if (hasPermissionInDomain) {
        return [bigNumberify(domainId), MaxUint256];
    }
    // @TODO once we allow nested domains on the network level, this needs to traverse down the skill/domain tree. Use binary search
    const foundDomainId = bigNumberify(ROOT_DOMAIN_ID);
    const hasPermissionInAParentDomain = yield contract.hasUserRole(walletAddress, foundDomainId, role);
    if (!hasPermissionInAParentDomain) {
        throw new Error(`User does not have the permission ${role} in any parent domain`);
    }
    const idx = yield getChildIndex(contract, foundDomainId, domainId);
    if (idx.lt(0)) {
        throw new Error(`User does not have the permission ${role} in any parent domain`);
    }
    return [foundDomainId, idx];
});
export const getMoveFundsPermissionProofs = (contract, fromtPotId, toPotId, customAddress) => __awaiter(void 0, void 0, void 0, function* () {
    const walletAddress = customAddress || (yield contract.signer.getAddress());
    const fromDomainId = yield getPotDomain(contract, fromtPotId);
    const toDomainId = yield getPotDomain(contract, toPotId);
    const [fromPermissionDomainId, fromChildSkillIndex,] = yield getPermissionProofs(contract, fromDomainId, ColonyRole.Funding, walletAddress);
    // @TODO: once getPermissionProofs is more expensive we can just check the domain here
    // with userHasRole and then immediately get the permission proofs
    const [toPermissionDomainId, toChildSkillIndex] = yield getPermissionProofs(contract, toDomainId, ColonyRole.Funding, walletAddress);
    // Here's a weird case. We have found permissions for these domains but they don't share
    // a parent domain with that permission. We can still find a common parent domain that
    // has the funding permission
    if (!fromPermissionDomainId.eq(toPermissionDomainId)) {
        const hasFundingInRoot = yield contract.hasUserRole(walletAddress, ROOT_DOMAIN_ID, ColonyRole.Funding);
        // @TODO: In the future we have to not only check the ROOT domain but traverse the tree
        // (binary search) to find a common parent domain with funding permission
        if (hasFundingInRoot) {
            const rootFromChildSkillIndex = yield getChildIndex(contract, ROOT_DOMAIN_ID, fromDomainId);
            const rootToChildSkillIndex = yield getChildIndex(contract, ROOT_DOMAIN_ID, toDomainId);
            // This shouldn't really happen as we have already checked whether the user has funding
            if (rootFromChildSkillIndex.lt(0) || rootToChildSkillIndex.lt(0)) {
                throw new Error(`User does not have the funding permission in any parent domain`);
            }
            return [
                fromPermissionDomainId,
                rootFromChildSkillIndex,
                rootToChildSkillIndex,
            ];
        }
        throw new Error(
        // eslint-disable-next-line max-len
        'User has to have the funding role in a domain that both associated pots a children of');
    }
    return [fromPermissionDomainId, fromChildSkillIndex, toChildSkillIndex];
});
export const getExtensionPermissionProofs = (colonyClient, domainId, address) => __awaiter(void 0, void 0, void 0, function* () {
    const [fundingPDID, fundingCSI] = yield getPermissionProofs(colonyClient, domainId, ColonyRole.Funding, address);
    const [adminPDID, adminCSI] = yield getPermissionProofs(colonyClient, domainId, ColonyRole.Administration, address);
    if (!fundingPDID.eq(adminPDID) || !fundingCSI.eq(adminCSI)) {
        // @TODO: this can surely be improved
        throw new Error(`${address || 'User'} has to have the funding and administration role in the same domain`);
    }
    return [adminPDID, adminCSI];
});
function getExtensionClient(extensionName) {
    return __awaiter(this, void 0, void 0, function* () {
        const extensionAddress = yield this.networkClient.getExtensionInstallation(getExtensionHash(extensionName), this.address);
        if (extensionAddress === AddressZero) {
            throw new Error(`${extensionName} extension is not installed for this colony`);
        }
        const extensionVersionClient = getExtensionVersionClient(extensionAddress, this.signer || this.provider);
        const versionBN = yield extensionVersionClient.version();
        const version = versionBN.toNumber();
        const { default: getVersionedExtensionClient, } = require(`../../Extensions/${extensionName}/${version}/${extensionName}Client`);
        return getVersionedExtensionClient(extensionAddress, this);
    });
}
function setArchitectureRoleWithProofs(_user, _domainId, _setTo, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        let proofs;
        // This method has two potential permissions, so we try both of them
        try {
            proofs = yield getPermissionProofs(this, _domainId, ColonyRole.Architecture);
        }
        catch (err) {
            proofs = yield getPermissionProofs(this, _domainId, ColonyRole.Root);
        }
        const [permissionDomainId, childSkillIndex] = proofs;
        return this.setArchitectureRole(permissionDomainId, childSkillIndex, _user, _domainId, _setTo, overrides);
    });
}
function setFundingRoleWithProofs(_user, _domainId, _setTo, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        let proofs;
        // This method has two potential permissions, so we try both of them
        try {
            proofs = yield getPermissionProofs(this, _domainId, ColonyRole.Architecture);
        }
        catch (err) {
            proofs = yield getPermissionProofs(this, _domainId, ColonyRole.Root);
        }
        const [permissionDomainId, childSkillIndex] = proofs;
        return this.setFundingRole(permissionDomainId, childSkillIndex, _user, _domainId, _setTo, overrides);
    });
}
function setAdministrationRoleWithProofs(_user, _domainId, _setTo, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        let proofs;
        // This method has two potential permissions, so we try both of them
        try {
            proofs = yield getPermissionProofs(this, _domainId, ColonyRole.Architecture);
        }
        catch (err) {
            proofs = yield getPermissionProofs(this, _domainId, ColonyRole.Root);
        }
        const [permissionDomainId, childSkillIndex] = proofs;
        return this.setAdministrationRole(permissionDomainId, childSkillIndex, _user, _domainId, _setTo, overrides);
    });
}
function addDomainWithProofs(_parentDomainId, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _parentDomainId, ColonyRole.Architecture);
        return this.addDomain(permissionDomainId, childSkillIndex, _parentDomainId, overrides);
    });
}
function addPaymentWithProofs(_recipient, _token, _amount, _domainId, _skillId, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Administration);
        return this.addPayment(permissionDomainId, childSkillIndex, _recipient, _token, _amount, _domainId, _skillId, overrides);
    });
}
function finalizePaymentWithProofs(_id, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getPayment(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Administration);
        return this.finalizePayment(permissionDomainId, childSkillIndex, _id, overrides);
    });
}
function setPaymentRecipientWithProofs(_id, _recipient, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getPayment(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Administration);
        return this.setPaymentRecipient(permissionDomainId, childSkillIndex, _id, _recipient, overrides);
    });
}
function setPaymentSkillWithProofs(_id, _skillId, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getPayment(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Administration);
        return this.setPaymentSkill(permissionDomainId, childSkillIndex, _id, _skillId, overrides);
    });
}
function setPaymentPayoutWithProofs(_id, _token, _amount, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getPayment(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Administration);
        return this.setPaymentPayout(permissionDomainId, childSkillIndex, _id, _token, _amount, overrides);
    });
}
function makeTaskWithProofs(_specificationHash, _domainId, _skillId, _dueDate, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Administration);
        return this.makeTask(permissionDomainId, childSkillIndex, _specificationHash, _domainId, _skillId, _dueDate, overrides);
    });
}
function moveFundsBetweenPotsWithProofs(_fromPot, _toPot, _amount, _token, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, fromChildSkillIndex, toChildSkillIndex,] = yield getMoveFundsPermissionProofs(this, _fromPot, _toPot);
        return this[`moveFundsBetweenPots(uint256,uint256,uint256,uint256,uint256,uint256,address)`](permissionDomainId, fromChildSkillIndex, toChildSkillIndex, _fromPot, _toPot, _amount, _token, overrides);
    });
}
function estimateSetArchitectureRoleWithProofs(_user, _domainId, _setTo) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Architecture);
        return this.estimate.setArchitectureRole(permissionDomainId, childSkillIndex, _user, _domainId, _setTo);
    });
}
function estimateSetFundingRoleWithProofs(_user, _domainId, _setTo) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Architecture);
        return this.estimate.setFundingRole(permissionDomainId, childSkillIndex, _user, _domainId, _setTo);
    });
}
function estimateSetAdministrationRoleWithProofs(_user, _domainId, _setTo) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Architecture);
        return this.estimate.setAdministrationRole(permissionDomainId, childSkillIndex, _user, _domainId, _setTo);
    });
}
function estimateAddDomainWithProofs(_parentDomainId) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _parentDomainId, ColonyRole.Architecture);
        return this.estimate.addDomain(permissionDomainId, childSkillIndex, _parentDomainId);
    });
}
function estimateAddPaymentWithProofs(_recipient, _token, _amount, _domainId, _skillId) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Administration);
        return this.estimate.addPayment(permissionDomainId, childSkillIndex, _recipient, _token, _amount, _domainId, _skillId);
    });
}
function estimateFinalizePaymentWithProofs(_id) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getPayment(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Administration);
        return this.estimate.finalizePayment(permissionDomainId, childSkillIndex, _id);
    });
}
function estimateSetPaymentRecipientWithProofs(_id, _recipient) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getPayment(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Administration);
        return this.estimate.setPaymentRecipient(permissionDomainId, childSkillIndex, _id, _recipient);
    });
}
function estimateSetPaymentSkillWithProofs(_id, _skillId) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getPayment(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Administration);
        return this.estimate.setPaymentSkill(permissionDomainId, childSkillIndex, _id, _skillId);
    });
}
function estimateSetPaymentPayoutWithProofs(_id, _token, _amount) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId } = yield this.getPayment(_id);
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, domainId, ColonyRole.Administration);
        return this.estimate.setPaymentPayout(permissionDomainId, childSkillIndex, _id, _token, _amount);
    });
}
function estimateMakeTaskWithProofs(_specificationHash, _domainId, _skillId, _dueDate) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, childSkillIndex] = yield getPermissionProofs(this, _domainId, ColonyRole.Administration);
        return this.estimate.makeTask(permissionDomainId, childSkillIndex, _specificationHash, _domainId, _skillId, _dueDate);
    });
}
function estimateMoveFundsBetweenPotsWithProofs(_fromPot, _toPot, _amount, _token) {
    return __awaiter(this, void 0, void 0, function* () {
        const [permissionDomainId, fromChildSkillIndex, toChildSkillIndex,] = yield getMoveFundsPermissionProofs(this, _fromPot, _toPot);
        return this.estimate[`moveFundsBetweenPots(uint256,uint256,uint256,uint256,uint256,uint256,address)`](permissionDomainId, fromChildSkillIndex, toChildSkillIndex, _fromPot, _toPot, _amount, _token);
    });
}
function getReputation(skillId, address, customRootHash) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!isAddress(address)) {
            throw new Error('Please provide a valid address');
        }
        const { network, reputationOracleEndpoint } = this.networkClient;
        const skillIdString = bigNumberify(skillId).toString();
        const rootHash = customRootHash || (yield this.networkClient.getReputationRootHash());
        const response = yield fetch(`${reputationOracleEndpoint}/${network}/${rootHash}/${this.address}/${skillIdString}/${address}`);
        const result = yield response.json();
        return Object.assign(Object.assign({}, result), { reputationAmount: bigNumberify(result.reputationAmount || 0) });
    });
}
function getMembersReputation(skillId) {
    return __awaiter(this, void 0, void 0, function* () {
        const { network, reputationOracleEndpoint } = this.networkClient;
        const skillIdString = bigNumberify(skillId).toString();
        const rootHash = yield this.networkClient.getReputationRootHash();
        const response = yield fetch(`${reputationOracleEndpoint}/${network}/${rootHash}/${this.address}/${skillIdString}`);
        return response.json();
    });
}
function deployTokenAuthority(tokenAddress, allowedToTransfer, overrides) {
    return __awaiter(this, void 0, void 0, function* () {
        const tokenAuthorityFactory = new ContractFactory(tokenAuthorityAbi, tokenAuthorityBytecode, this.signer);
        const tokenAuthorityContract = yield tokenAuthorityFactory.deploy(tokenAddress, this.address, allowedToTransfer, overrides);
        yield tokenAuthorityContract.deployed();
        return tokenAuthorityContract.deployTransaction;
    });
}
function estimateDeployTokenAuthority(tokenAddress, allowedToTransfer) {
    return __awaiter(this, void 0, void 0, function* () {
        const tokenAuthorityFactory = new ContractFactory(tokenAuthorityAbi, tokenAuthorityBytecode);
        const deployTx = tokenAuthorityFactory.getDeployTransaction(tokenAddress, this.address, allowedToTransfer);
        return this.provider.estimateGas(deployTx);
    });
}
export const addExtensions = (instance, networkClient) => {
    /* eslint-disable no-param-reassign, max-len */
    instance.clientType = ClientType.ColonyClient;
    instance.networkClient = networkClient;
    instance.deployTokenAuthority = deployTokenAuthority.bind(instance);
    instance.getExtensionClient = getExtensionClient.bind(instance);
    instance.setArchitectureRoleWithProofs = setArchitectureRoleWithProofs.bind(instance);
    instance.setFundingRoleWithProofs = setFundingRoleWithProofs.bind(instance);
    instance.setAdministrationRoleWithProofs = setAdministrationRoleWithProofs.bind(instance);
    instance.addDomainWithProofs = addDomainWithProofs.bind(instance);
    instance.addPaymentWithProofs = addPaymentWithProofs.bind(instance);
    instance.finalizePaymentWithProofs = finalizePaymentWithProofs.bind(instance);
    instance.setPaymentRecipientWithProofs = setPaymentRecipientWithProofs.bind(instance);
    instance.setPaymentSkillWithProofs = setPaymentSkillWithProofs.bind(instance);
    instance.setPaymentPayoutWithProofs = setPaymentPayoutWithProofs.bind(instance);
    instance.makeTaskWithProofs = makeTaskWithProofs.bind(instance);
    instance.moveFundsBetweenPotsWithProofs = moveFundsBetweenPotsWithProofs.bind(instance);
    instance.estimate.deployTokenAuthority = estimateDeployTokenAuthority.bind(instance);
    instance.estimate.setArchitectureRoleWithProofs = estimateSetArchitectureRoleWithProofs.bind(instance);
    instance.estimate.setFundingRoleWithProofs = estimateSetFundingRoleWithProofs.bind(instance);
    instance.estimate.setAdministrationRoleWithProofs = estimateSetAdministrationRoleWithProofs.bind(instance);
    instance.estimate.addDomainWithProofs = estimateAddDomainWithProofs.bind(instance);
    instance.estimate.addPaymentWithProofs = estimateAddPaymentWithProofs.bind(instance);
    instance.estimate.finalizePaymentWithProofs = estimateFinalizePaymentWithProofs.bind(instance);
    instance.estimate.setPaymentRecipientWithProofs = estimateSetPaymentRecipientWithProofs.bind(instance);
    instance.estimate.setPaymentSkillWithProofs = estimateSetPaymentSkillWithProofs.bind(instance);
    instance.estimate.setPaymentPayoutWithProofs = estimateSetPaymentPayoutWithProofs.bind(instance);
    instance.estimate.makeTaskWithProofs = estimateMakeTaskWithProofs.bind(instance);
    instance.estimate.moveFundsBetweenPotsWithProofs = estimateMoveFundsBetweenPotsWithProofs.bind(instance);
    // This is awkward and just used to get the RecoveryRoleSet event which is missing (but emitted)
    // in other colonies. We can remove this once all of the active colonies are at version 4
    instance.awkwardRecoveryRoleEventClient = IColonyFactory.connect(instance.address, instance.provider);
    instance.getReputation = getReputation.bind(instance);
    instance.getMembersReputation = getMembersReputation.bind(instance);
    /* eslint-enable no-param-reassign, max-len */
    return instance;
};
